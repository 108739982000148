import * as yup from "yup";

export const validationSchema = yup.object({
  organizationId: yup.string().required(),
  deviceId: yup.string().required(),
  poi: yup.string().required(),
  segments: yup.array().of(
    yup.object().shape({
      segmentId: yup.string().required(),
      name: yup.string().required(),
      sourceTag: yup.string().required(),
      distance: yup.number(),
      points: yup.array().of(
        yup.object().shape({
          x: yup.number().required(),
          y: yup.number().required()
        })
      )
    })
  ),
  eventDate: yup.string().required()
});
