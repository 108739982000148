import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import { Route } from "react-router-dom";

import { makeStyles } from "@kuva/ui-components";

import { Loading } from "~/common/components";

import { getAlarms } from "../../common/selectors/AlarmSelector";

import AlarmDetails from "./components/AlarmDetails";
import { AlarmsList } from "./components/AlarmList";

const useStyles = makeStyles()({
  root: {
    color: "white",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%"
  }
});

const Alarms = ({
  addEditAlarm,
  deleteAlarm,
  scresOptions,
  alarmsLoading,
  handleDateChange,
  reviewerMode
}) => {
  const { classes } = useStyles();
  const alarms = useSelector(getAlarms, shallowEqual);

  return (
    <div className={classes.root}>
      {alarmsLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto"
          }}
        >
          <Typography variant="h6">Loading Alarms...</Typography>
          <Loading />
        </div>
      ) : alarms.length ? (
        <>
          <AlarmsList alarms={alarms} />
          <Route path="/alarms/:orgId?/:deviceId?/:alarmId?/:streamId?">
            <AlarmDetails
              addEditAlarm={addEditAlarm}
              deleteAlarm={deleteAlarm}
              scresOptions={scresOptions}
              handleDateChange={handleDateChange}
              reviewerMode={reviewerMode}
            />
          </Route>
        </>
      ) : (
        <Box m="2em auto">
          <Typography
            style={{
              textAlign: "center",
              fontSize: "1.5em"
            }}
          >
            &empty; There are no alarms from this camera.
          </Typography>
        </Box>
      )}
    </div>
  );
};

Alarms.propTypes = {
  deleteAlarm: PropTypes.func.isRequired,
  addEditAlarm: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  alarmsLoading: PropTypes.bool,
  scresOptions: PropTypes.shape({
    showNonDetections: PropTypes.bool.isRequired,
    eventConf: PropTypes.number.isRequired,
    minuteGap: PropTypes.number.isRequired,
    frameMinimum: PropTypes.number.isRequired,
    paddedFrames: PropTypes.number.isRequired
  }).isRequired,
  reviewerMode: PropTypes.string
};

export default Alarms;
